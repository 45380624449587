<template>
  <v-treeview
    :items="sortedCategories()"
    item-text="name"
    item-key="uuid"
    item-children="subCategories"
    activatable
    open-all
    hoverable
  >
    <template v-slot:label="{ item }">
      <span>{{ item.name }} <span><v-btn icon><v-icon>mdi-arrow-up-bold</v-icon></v-btn><v-chip label>{{ item.weight }}</v-chip><v-btn icon><v-icon>mdi-arrow-down-bold</v-icon></v-btn></span> <v-chip
        small
        class="mr-2"
      >{{ itemsInCategory(item).length }} items in category</v-chip><v-chip small>{{ itemsInEtags(item).length }} items in eTags</v-chip><v-btn
        icon
        @click="goTo(item)"
      ><v-icon>mdi-pencil</v-icon></v-btn></span>
    </template>
  </v-treeview>
</template>

<script>
export default {
	name: "CategoryTree",
	props: {
		categories: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		itemsList() {
			return this.$store.state.items
		}
	},
	methods: {
		goTo(item) {
			this.$router.push({path: `/store/categories/${item.uuid}`})
		},
		itemsInCategory(category) {
			return this.itemsList.filter(item => item.categories && item.categories.length && item.categories.some(c => c.uuid === category.uuid))
		},
		itemsInEtags(category) {
			return this.itemsInCategory(category).filter(item => item.categories && item.categories.length && item.categories.some(c => c.name === 'eTags'))
		},
		sortedCategories() {
			let cats = this.categories
			for(const i in cats) {
				if(cats[i].parent && cats[i].parent.length) {
					cats[i].parent.forEach(p => {
						let c = cats.find(s => s.uuid === p.uuid)
						if(c) {
							if(!c.hasSubCategories) { c.hasSubCategories = true }
							if(!c.subCategories || !c.subCategories.length) { c.subCategories = [] }
							if(!c.subCategories.includes(cats[i])) {
								c.subCategories.push(cats[i])
							}
						}
					})
				} else {
					if(!cats[i].isTopLevel) { cats[i].isTopLevel = true }
				}
			}
			return cats.filter(category => category.isTopLevel)
		},
	}
}
</script>

<style scoped>

</style>